import React from 'react'
import { Link } from 'gatsby'
/* import { graphql } from 'gatsby' */

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import styles from "./thankyou.module.css"

const ThankyouPage = () => (
	<Layout>
	<SEO title="送信完了ページ" />
		<div className={styles.container}>
			<h1>送信完了</h1>
			<div className={styles.body}>
				<p className={styles.message}>
				お問い合わせありがとうございました。
				</p>

				<Link to="/">トップページに戻る</Link>
			</div>
		</div>
	</Layout>
)

export default ThankyouPage

/* [EOF] */
